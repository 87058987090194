import request from '@/utils/request'

// 招募页活动列表
export function getRecruitList(params,name) {
  return request({
    url: `/v2/api/app/ad_activity/activity/query_recruit_page?page=${params.page}&size=${params.size}&appName=${params.appName}&name=${name}`,
    method: 'get',
  })
}
//根据招募活动id获取详情
export function getRecruitById(params) {
    return request({
      url: `/v2/api/app/ad_activity/activity/query_web_info?id=${params.activityId}&memberId=${params.memberId}`,
      method: 'get',
    })

}
export function getRecruitByIdSec(params) {
  return request({
    url: `/v2/api/app/ad_activity//activity/query_web_info/second_phase?id=${params.activityId}&memberId=${params.memberId}`,
    method: 'get',
  })
}
//分享页获取详情
export function getshareRecruitById(params) {
  return request({
    url: `/v2/api/app/ad_activity//activity/queryActivityInfo?id=${params.activityId}`,
    method: 'get',
  })
}
//根据招募活动id获取活动报名人数
export function getRecruitMemberById(id) {
    return request({
      url: `/v2/api/app/ad_activity/activity/query_activity_member_count?activityId=${id}`,
      method: 'get',
    })
}
//报名招募活动
export function sureActivity(params) {
    return request({
      url: `/v2/api/app/ad_activity/activity/add/sign_up`,
      method: 'post',
      params
    })
}
// 取消招募报名
export function cancelActivity(params) {
    return request({
      url: `/v2/api/app/ad_activity/activity/del/sign_up`,
      method: 'post',
      params
    })
}
// 根据活动id获取上传的图片列表
export function getPhotoList(id) {
  return request({
    url: `/v2/api/app/ap_media//app/mediaManage/selectActivityMedia?activityId=${id}`,
    method: 'get',
  })
}

// 所有活动列表
export function allactiveData(params) {
  return request({
    url: `/v2/api/app/ad_activity//activity/queryActivityAllPage`,
    method: 'get',
    params
  })
}
// 所有活动列表 二期
export function allactiveDataSec(params) {
  return request({
    url: `/v2/api/app/ad_activity//activity/queryActivityAllPage/second_phase`,
    method: 'get',
    params
  })
}
// 会员对活动进行评分
export function addGrade(data,params) {
  return request({
    url: `/v2/api/app/ap_member//activityOpinion/memberToActivityOpinion`,
    method: 'post',
    data: data,
    params: params
  })
}

// 查询活动评分列表
export function getGrade(params) {
  return request({
    url: `/v2/api/app/ap_member//activityOpinion/memberToActivityOpinionPage`,
    method: 'post',
    params: params
  })
}

export default { allactiveDataSec,getRecruitByIdSec,getshareRecruitById,addGrade, getGrade ,getRecruitList, getRecruitById, getRecruitMemberById, sureActivity, cancelActivity, getPhotoList,allactiveData }