import request from '@/utils/request'
// import request from '@/utils/http'
// 获取文件的所有目录,文件夹
export function getDirectory() {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/selectFolder',
    method: 'post'
  })
}

// 校验文件是否能上传,能上传，判断文件是不是上传过了，上传过了将文件的值
export function judgetFile(params,data) {
  return request({
    url: 'v2/api/app/ap_media//app/mediaManage/selectMediaFileIsUpload',
    method: 'post',
    data: data,
    params:params
  })
}

// 创建文件，将文件入库
export function warehousing(data) {
  return request({
    url: 'v2/api/app/ap_media//app/mediaManage/addMediaFile',
    method: 'post',
    data: data
  })
}

// 根据Id查询素材的详细信息
export function fileId(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/findMediaFileInfo',
    method: 'post',
    data: data
  })
}

// 根据Id查询素材操作记录
export function fileOpration(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/findMediaLog',
    method: 'post',
    data: data
  })
}

// 移动文件以及文件夹
export function moveMediaFile(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/moveMediaFile',
    method: 'post',
    data: data
  })
}

// 彻底删除文件
export function delMediaFile(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/delMediaFile',
    method: 'post',
    data: data
  })
}

// 删除文件夹以及文件
export function removeMediaFile(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/removeMediaFile',
    method: 'post',
    data: data
  })
}

// 还原文件以及文件夹
export function returnMediaFile(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/returnMediaFile',
    method: 'post',
    data: data
  })
}

// 分页查询,栏目素材的查询
export function selectMediaFileByPage(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/selectMediaFileByPage',
    method: 'post',
    data: data
  })
}

// 分页查询,回收站的查询
export function selectMediaFileRecycleByPage(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/selectMediaFileRecycleByPage',
    method: 'post',
    data: data
  })
}

// 查看这个文件夹下的素材
export function selectMediaInFolderByPage(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/selectMediaInFolderByPage',
    method: 'post',
    data: data
  })
}

// 文件的修改，修改只是关键字，描述，
export function updateMediaFile(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/updateMediaFile',
    method: 'post',
    data: data
  })
}

// 重命名
export function updateMediaFileName(data) {
  return request({
    url: 'v2/api/ad_media//admin/mediaManage/updateMediaFileName',
    method: 'post',
    data: data
  })
}

export default {
  getDirectory,
  judgetFile,
  warehousing,
  fileId,
  fileOpration,
  moveMediaFile,
  removeMediaFile,
  delMediaFile,
  returnMediaFile,
  selectMediaFileByPage,
  selectMediaFileRecycleByPage,
  selectMediaInFolderByPage,
  updateMediaFile,
  updateMediaFileName
}
